import React from "react";
import { Box, useColorModeValue } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import { LandingPageKnowledge } from "../components/landingPage/LandingPageKnowledge";
import { LandingPageAbout } from "../components/landingPage/LandingPageAbout";
import { LandingPageHelp } from "../components/landingPage/LandingPageHelp";
import { LandingPageThrive } from "../components/landingPage/LandingPageThrive";
import { facebookLink, instagramLink } from "../constants";

export const LandingPage = () => {
  return (
    <Box fontFamily={`'Baloo 2', sans-serif`}>
      <Helmet>
        <title>Muttley's Doggy Daycare - Home</title>
        <meta name="description" content="Welcome to Muttley's Doggy Daycare! Explore our safe, joyful, and nurturing environment where every dog can thrive. Learn more about our services, our commitment to canine care, and how we help your pets live their best lives." />
        <meta name="keywords" content="doggy daycare, dog care, dog training, Bournemouth, Christchurch, pet services, canine care" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Muttley's Doggy Daycare",
            url: "https://muttleysdoggydaycare.com",
            logo: "https://muttleysdoggydaycare.com/logo.png",
            sameAs: [facebookLink, instagramLink],
            description: "Muttley's Doggy Daycare offers a safe, joyful, and nurturing environment where every dog can thrive. Our expert staff provide top-notch care, training, and enrichment activities for dogs of all ages.",
            address: {
              "@type": "PostalAddress",
              streetAddress: "Home Farm Hurn Christchurch",
              addressLocality: "Christchurch",
              postalCode: "BH23 6AE",
              addressCountry: "UK",
            },
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+44-1202-090704",
              contactType: "Customer Service",
            },
            potentialAction: {
              "@type": "SearchAction",
              target: "https://muttleysdoggydaycare.com/search?q={search_term_string}",
              "query-input": "required name=search_term_string",
            },
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": "https://muttleysdoggydaycare.com",
            },
          })}
        </script>
      </Helmet>

      {/* About Us Section */}
      <LandingPageKnowledge />

      <LandingPageAbout />

      <LandingPageHelp />

      <LandingPageThrive />
    </Box>
  );
};
