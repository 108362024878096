import React, { useEffect, useState } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import "./main.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { HelmetProvider } from "react-helmet-async";
import { AllRoutes } from "./Routes";
import ReactGA from "react-ga4";

export const adminApiUrl = "https://noxy-admin-api-hcgmsatdmq-nw.a.run.app/admin-api/doggy-day-care";

export const App = () => {
  useEffect(() => {
    ReactGA.initialize("G-BYT0RLQ9RQ");
    ReactGA.send({ hitType: "pageview", page: "/", title: "Landing Page" });
  }, []);

  return (
    <HelmetProvider>
      <ChakraProvider>
        <AllRoutes />
      </ChakraProvider>
    </HelmetProvider>
  );
};
