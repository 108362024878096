import React from "react";
import { Box } from "@chakra-ui/react";
import { FaPhone, FaEnvelope, FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import { Helmet } from "react-helmet-async";
import { ContactOpeningHours } from "../components/contact/ContactOpeningHours";
import { ContactFollowUs } from "../components/contact/ContactFollowUs";
import { ContactMap } from "../components/contact/ContactMap";
import { facebookLink, instagramLink } from "../constants";

export const ContactUs = () => {
  return (
    <Box bg="white">
      <Helmet>
        <title>Contact Us - Muttley's Doggy Daycare</title>
        <meta name="description" content="Get in touch with Muttley's Doggy Daycare. Contact us via phone, email, or visit us at our location in Christchurch. We're here to assist you and your pets." />
        <meta name="keywords" content="contact doggy daycare, pet care contact, Muttley's Doggy Daycare contact, dog care hours, dog daycare location" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ContactPage",
            url: "https://muttleysdoggydaycare.com/contact",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+44 1202 090704",
                contactType: "Customer Service",
                availableLanguage: "English",
              },
            ],
            address: {
              "@type": "PostalAddress",
              streetAddress: "Home Farm Hurn Christchurch",
              addressLocality: "Christchurch",
              postalCode: "BH23 6AE",
              addressCountry: "UK",
            },
            sameAs: [facebookLink, instagramLink],
          })}
        </script>
      </Helmet>
      <ContactOpeningHours />
      <ContactFollowUs />
      <ContactMap />
    </Box>
  );
};
