import { Box, Grid, GridItem, Heading, HStack, Img, Link, Text } from "@chakra-ui/react";
import React from "react";
import { FaChevronRight, FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import { facebookLink, instagramLink, lightGreen, primaryGreen } from "../../constants";
import Dog1 from "../../assets/footer-dog-1.png";
import Dog2 from "../../assets/footer-dog-2.png";
import Logo from "../../assets/logo.png";

export const Footer = () => {
  const navigate = useNavigate();

  const LinkItem: React.FC<{ text: string; onClick: () => void }> = ({ text, onClick }) => (
    <HStack>
      <FaChevronRight color={lightGreen} />
      <Link as={Text} fontFamily={`'Baloo 2', sans-serif`} fontSize="lg" color="white" onClick={onClick}>
        {text}
      </Link>
    </HStack>
  );

  return (
    <Box bg={primaryGreen} as="footer" py={{ base: 12, md: 20 }} position="relative">
      <Box maxW="7xl" mx="auto" px={6}>
        <Grid templateColumns="repeat(3, 1fr)" gap={8}>
          <GridItem>
            <Grid templateRows="1fr 1fr" gap={20}>
              <GridItem>
                <Img src={Logo} width={100} height="auto" alt="Logo" />
                <Text color="white" mt={4} fontFamily={`'Baloo 2', sans-serif`}>
                  Copyright {new Date().getFullYear()}
                </Text>
                <Text color="white" mt={4} fontFamily={`'Baloo 2', sans-serif`}>
                  Licence No. BXC00493
                </Text>
              </GridItem>
              <GridItem>
                <HStack spacing={4}>
                  <Link href={instagramLink} target="_blank" rel="noopener noreferrer">
                    <FaInstagram size={30} color="white" />
                  </Link>
                  <Link href={facebookLink} target="_blank" rel="noopener noreferrer">
                    <FaFacebook size={30} color="white" />
                  </Link>
                </HStack>
              </GridItem>
            </Grid>
          </GridItem>

          <GridItem>
            <Heading as="h3" size="lg" color="white" fontFamily={`'Baloo 2', sans-serif`} mb={4}>
              Quick Links
            </Heading>
            <LinkItem text="About" onClick={() => navigate("about")} />
            <LinkItem text="Services" onClick={() => navigate("services")} />
            <LinkItem text="FAQ's" onClick={() => navigate("faqs")} />
            <LinkItem text="Terms & Conditions" onClick={() => navigate("terms-and-conditions")} />
          </GridItem>

          <GridItem>
            <Box position="relative" display="flex" justifyContent="center" alignItems="flex-end" bottom={-4}>
              <Img src={Dog1} alt="Dog 1" width={{ base: "50px", md: "100px", lg: "150px" }} />
              <Img src={Dog2} alt="Dog 2" width={{ base: "50px", md: "100px", lg: "150px" }} />
            </Box>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
};
