import React from "react";
import { Box } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";

import { DaycareFacilities } from "../components/daycare/DaycareFacilities";
import { DaycareExperience } from "../components/daycare/DaycareExperience";
import { DaycareGoal } from "../components/daycare/DaycareGoal";
import { DaycareJoiningProcess } from "../components/daycare/DayCareJoiningProcess";
import { DaycareGallery } from "../components/daycare/DaycareGallery";
import { DaycareMembershipFees } from "../components/daycare/DaycareMembershipFees";

export const DaycarePage = () => {
  return (
    <Box bg="white" id="daycare">
      <Helmet>
        <title>Doggy Daycare - Muttley's Doggy Daycare</title>
        <meta name="description" content="Discover the ultimate doggy daycare experience at Muttley's. Our facilities offer safe and fun environments for dogs, with personalized care and engaging activities to keep your pup happy and healthy." />
        <meta name="keywords" content="doggy daycare, dog care, pet care, dog services, Muttley's Doggy Daycare" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Service",
            serviceType: "Doggy Daycare",
            provider: {
              "@type": "Organization",
              name: "Muttley's Doggy Daycare",
              url: "https://muttleysdoggydaycare.com",
            },
            serviceArea: {
              "@type": "Place",
              name: "Bournemouth, Christchurch, Poole",
            },
            description: "Muttley's Doggy Daycare offers top-notch facilities, personalized care, and engaging activities to ensure your dog enjoys a safe and fun-filled day.",
            offers: [
              {
                "@type": "Offer",
                name: "Daycare Facilities",
                description: "Safe and spacious environment tailored for both play and socialization. Our facilities include expansive indoor and outdoor areas for dogs to enjoy.",
                url: "https://muttleysdoggydaycare.com/daycare-facilities",
              },
              {
                "@type": "Offer",
                name: "Daycare Experience",
                description: "Personalized attention for every dog with one-on-one interaction to reinforce good behavior and address emerging behavioral changes.",
                url: "https://muttleysdoggydaycare.com/daycare-experience",
              },
              {
                "@type": "Offer",
                name: "Daycare Goal",
                description: "Our goal is to promote your dog’s overall well-being through regular exercise, mental stimulation, and socialization in a structured environment.",
                url: "https://muttleysdoggydaycare.com/daycare-goal",
              },
              {
                "@type": "Offer",
                name: "Joining Process",
                description: "Learn about our easy joining process and get your dog started on their fun and enriching daycare journey.",
                url: "https://muttleysdoggydaycare.com/joining-process",
              },
              {
                "@type": "Offer",
                name: "Daycare Gallery",
                description: "View our gallery to see the exciting and safe environment your dog will enjoy at Muttley's Doggy Daycare.",
                url: "https://muttleysdoggydaycare.com/gallery",
              },
              {
                "@type": "Offer",
                name: "Membership and Fees",
                description: "Explore our membership options and fees. Sign up for our services and book your ‘FREE’ Daycare Meet & Greet.",
                url: "https://muttleysdoggydaycare.com/membership-fees",
              },
            ],
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": "https://muttleysdoggydaycare.com/daycare",
            },
          })}
        </script>
      </Helmet>

      <DaycareFacilities />
      <DaycareExperience />
      <DaycareGoal />
      <DaycareJoiningProcess />
      <DaycareGallery />
      <DaycareMembershipFees />
    </Box>
  );
};
