import React from "react";
import { Box, Grid, GridItem, Image } from "@chakra-ui/react";
import WavyBg from "../../assets/primary-green-wavy-bg.png";
import { Card } from "../shared/Card";
import Daycare from "../../assets/daycare.jpeg";
import Behavioural from "../../assets/behavioural-training.jpeg";
import Puppies from "../../assets/puppies.jpeg";

import { lightGreen } from "../../constants";
import { useNavigate } from "react-router-dom";

export const ServicesList = () => {
  const navigate = useNavigate();
  return (
    <Box bgColor={lightGreen} as="section" py={{ base: 20, md: 40 }} pos="relative" overflow="hidden">
      <Box maxW="7xl" mx="auto" px={6}>
        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr" }} gap={10}>
          <GridItem zIndex={9}>
            <Card img={Daycare} title="Doggy Daycare" description="Safe, fun playtime with expert care, leaving your pup happy and well-exercised!" onClick={() => navigate("/daycare")} />
          </GridItem>
          <GridItem zIndex={9}>
            <Card img={Behavioural} title="Behavioural and Training" description="Expert dog training, improving behavior in dogs while giving owners confidence and control." onClick={() => navigate("/behavioural")} />
          </GridItem>
          <GridItem zIndex={9}>
            <Card img={Puppies} title="Puppies" description="Empowering owners with expert guidance for confident, well-rounded, and socialised puppies during their critical growth phase." onClick={() => navigate("/puppies")} />
          </GridItem>
        </Grid>
      </Box>
      <Image src={WavyBg} alt="Grass bottom decoration" position="absolute" bottom="-1" left="-10vw" right="0" minW="120vw" zIndex={0} />
    </Box>
  );
};
