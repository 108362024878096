import React from "react";
import { Box } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import { ServicesList } from "../components/services/ServicesList";

export const Services = () => {
  const services = [
    {
      name: "Doggy Daycare",
      description: "Safe, fun playtime with expert care, leaving your pup happy and well-exercised!",
      url: "/daycare",
    },
    {
      name: "Behavioural and Training",
      description: "Expert dog training, correcting bad behavior in dogs while giving owners confidence and control.",
      url: "/behavioural",
    },
    {
      name: "Puppies",
      description: "Empowering owners with expert guidance for confident, well-rounded, and socialised puppies during their critical growth phase.",
      url: "/puppies",
    },
  ];

  return (
    <Box>
      <Helmet>
        <title>Our Services - Muttley's Doggy Daycare</title>
        <meta name="description" content="Explore the wide range of services offered by Muttley's Doggy Daycare, including daycare, training, and more. We provide top-notch care for your beloved pets." />
        <meta name="keywords" content="doggy daycare services, dog training, pet care, Muttley's Doggy Daycare" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Service",
            serviceType: "Doggy Daycare Services",
            provider: {
              "@type": "Organization",
              name: "Muttley's Doggy Daycare",
              url: "https://muttleysdoggydaycare.com",
            },
            hasOfferCatalog: {
              "@type": "OfferCatalog",
              name: "Services",
              itemListElement: services.map((service) => ({
                "@type": "Offer",
                itemOffered: {
                  "@type": "Service",
                  name: service.name,
                  description: service.description,
                },
                url: `https://muttleysdoggydaycare.com${service.url}`,
              })),
            },
          })}
        </script>
      </Helmet>

      <ServicesList />
    </Box>
  );
};
