import React from "react";
import { Box, Heading, Text, Grid, GridItem, Center, Image, VStack } from "@chakra-ui/react";
import LandingPageAboutImg from "../../assets/landing-page-about.png";
import LandingPageAboutSupport from "../../assets/landing-page-about-dog-support.png";
import Paw from "../../assets/paw.png";
import { lightGreen, primaryGreen } from "../../constants";

export const LandingPageAbout = () => {
  return (
    <Box bg="white" as="section" py={{ base: 12, md: 20 }} position="relative">
      <Box maxW="7xl" mx="auto" px={6}>
        <Grid templateColumns={{ base: "1fr", md: "0.7fr 1fr" }} gap={20}>
          <GridItem>
            <VStack align="flex-start" justify="center" spacing={6}>
              <Heading as="h2" size="lg" color={lightGreen} fontFamily="'Baloo 2', sans-serif">
                About Muttley's
              </Heading>
              <Heading as="h2" size="xl" color={primaryGreen} fontFamily="'Baloo 2', sans-serif">
                We are committed to providing a safe, joyful, and nurturing environment where every dog can thrive.
              </Heading>
              <Text color="gray.700" fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
                Our focus is on creating a secure space where dogs can play, socialise, and grow with confidence. Through engaging activities that stimulate both body and mind, we ensure each dog experiences enrichment and happiness. With kindness at the heart of everything we do, we treat every dog with the love and respect they deserve. More than just a daycare, we are a community centered on the happiness and growth of every dog.
              </Text>
              <Image src={LandingPageAboutSupport} alt="Dog Support" borderRadius="md" />
            </VStack>
          </GridItem>
          <GridItem>
            <Center h="100%">
              <Image h="auto" w="70%" borderRadius="lg" src={LandingPageAboutImg} alt="Dog Field" />
            </Center>
          </GridItem>
        </Grid>
        <Image src={Paw} alt="Paw" position="absolute" bottom="0" right="0" w="20%" zIndex={9} />
      </Box>
    </Box>
  );
};
