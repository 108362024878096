import React, { useEffect, useRef } from "react";
import mapboxgl, { Map } from "mapbox-gl";
import Dog from "../../assets/about-review-dog-left.png";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY as string;

export const GlobeMap: React.FC = () => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<Map | null>(null);
  let userInteracting = false;

  const lng = -1.8189424;
  const lat = 50.7690593;

  useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current as HTMLElement,
      style: "mapbox://styles/mapbox/streets-v9",
      zoom: 13,
      center: [lng, lat],
    });

    // Add navigation controls (zoom and rotation controls)
    const navControl = new mapboxgl.NavigationControl();
    map.current.addControl(navControl, "top-right"); // Add to top-right corner

    // Create a custom marker using an HTML element
    const el = document.createElement("div");
    el.className = "custom-marker";
    el.style.backgroundImage = `url(${Dog})`;
    el.style.width = "50px"; // Customize the width of the pin
    el.style.height = "100px"; // Customize the height of the pin
    el.style.backgroundSize = "100%";
    el.style.objectFit = "fill";
    el.style.backgroundRepeat = "no-repeat";

    // Add custom marker to map
    new mapboxgl.Marker(el).setLngLat([lng, lat]).addTo(map.current!);

    // Disable scroll zoom
    map.current.scrollZoom.disable();

    map.current.on("style.load", () => {
      map.current!.setFog({}); // Set the default atmosphere style
    });

    map.current.on("mousedown", () => {
      userInteracting = true;
    });

    map.current.on("dragstart", () => {
      userInteracting = true;
    });
  }, []);

  return (
    <div style={{ position: "relative", height: "100vh", width: "100%" }}>
      <div id="map" ref={mapContainer} style={{ position: "absolute", top: 0, bottom: 0, width: "100%" }} />
    </div>
  );
};
