import React from "react";
import { Box, Heading, Text, Grid, GridItem, Center, Image, VStack } from "@chakra-ui/react";

import Paw from "../../assets/paw.png";

import DaycareGoalImg from "../../assets/dayacare-our-goal.png";
import { lightGreen, primaryGreen } from "../../constants";

export const DaycareGoal = () => {
  return (
    <Box bg="white" as="section" py={{ base: 12, md: 20 }} position="relative">
      <Box maxW="7xl" mx="auto" px={6}>
        <Grid templateColumns={{ base: "1fr", md: "0.7fr 1fr" }} gap={20}>
          <GridItem>
            <VStack align="flex-start" justify="center" spacing={6}>
              <Heading as="h2" size="lg" color={lightGreen} fontFamily="'Baloo 2', sans-serif">
                Our Goal
              </Heading>
              <Heading as="h2" size="lg" color={primaryGreen} fontFamily="'Baloo 2', sans-serif">
                Our goal is not just to provide a fun day out but to promote your dog’s overall well-being.
              </Heading>
              <Text color="gray.700" fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
                By offering regular exercise, mental stimulation, and socialisation in a structured environment, we help your dog stay healthy, confident, and content.
                <br />
                <br />
                When you choose our Doggy Daycare, you’re giving your dog the opportunity to thrive in a positive, supportive setting that encourages their happiness and well-being, leaving them eager for their next visit.
              </Text>
            </VStack>
          </GridItem>
          <GridItem>
            <Center h="100%">
              <Image h="auto" w="70%" borderRadius="lg" src={DaycareGoalImg} alt="Dog Field" />
            </Center>
          </GridItem>
        </Grid>
        <Image src={Paw} alt="Paw" position="absolute" bottom="0" right="0" w="20%" zIndex={9} />
      </Box>
    </Box>
  );
};
