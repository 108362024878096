import React from "react";
import { Box, Heading, Text, Image, VStack, HStack } from "@chakra-ui/react";

import Paw from "../../assets/paw.png";
import WavyBg from "../../assets/primary-green-wavy-bg.png";

import { lightGreen, primaryGreen, yellow } from "../../constants";
import { FaPaw } from "react-icons/fa";

const Icon = FaPaw;

const features = [
  { title: "Behavioural Analysis and Intervention:", text: "Skilled in diagnosing and addressing a wide range of behavioural issues in dogs through evidence-based methods." },
  { title: "Training and Education:", text: "Adept at educating dog owners and trainers, enhancing their ability to manage and modify dog behaviour." },
  { title: "Positive Reinforcement:", text: "Advocate for humane, positive reinforcement techniques that promote long-term behavioural change." },
  { title: "Rescue and Rehabilitation:", text: "Experienced in working with rescue dogs, helping them overcome trauma and behavioural problems to become well-adjusted pets." },
];

export const BehaviouralKeySkills = () => {
  return (
    <Box bg="white" as="section" py={{ base: 12, md: 20 }} position="relative">
      <Box maxW="7xl" mx="auto" px={6} mb={60}>
        <VStack align="flex-start" justify="center" spacing={6}>
          <Heading as="h2" size="lg" color={primaryGreen} fontFamily="'Baloo 2', sans-serif">
            Key Skills and Expertise
          </Heading>

          {features.map((feature, index) => (
            <HStack key={index} align="flex-start" spacing={3}>
              <Icon color={yellow} size="1.5em" />
              <Text fontSize="md" color="gray.800" fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
                <Text fontSize="md" color={lightGreen} fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
                  {feature.title}
                </Text>
                {feature.text}
              </Text>
            </HStack>
          ))}
        </VStack>

        <Image src={Paw} alt="Paw" position="absolute" bottom="0" right="0" w="20%" zIndex={9} />
      </Box>
      <Image src={WavyBg} alt="Grass bottom decoration" position="absolute" bottom="-1" left="-10vw" right="0" minW="120vw" zIndex={0} />
    </Box>
  );
};
