import React from "react";
import { Box, Heading, Text, Grid, GridItem, Center, Image, VStack, Img } from "@chakra-ui/react";
import { lightGreen, primaryGreen, yellow } from "../../constants";
import Icon1 from "../../assets/landing-page-thrive-icon-1.png";
import Icon2 from "../../assets/landing-page-thrive-icon-2.png";
import Icon3 from "../../assets/landing-page-thrive-icon-3.png";
import WavyBg from "../../assets/primary-green-wavy-bg.png";

const CardContent: React.FC<{ title: string; description: string; img: string }> = ({ title, description, img }) => (
  <VStack align="start" spacing={4} alignItems="center">
    <Image src={img} alt={title} boxSize="80px" objectFit="contain" />
    <Heading as="h3" textAlign="center" size="md" color={primaryGreen} fontFamily="'Baloo 2', sans-serif">
      {title}
    </Heading>
    <Text color={primaryGreen} textAlign="center" fontFamily="'Baloo 2', sans-serif" lineHeight={1.6}>
      {description}
    </Text>
  </VStack>
);

export const LandingPageThrive = () => {
  return (
    <Box bg={lightGreen} as="section" py={{ base: 12, md: 20 }} position="relative" overflow="hidden">
      <Box maxW="7xl" mx="auto" px={6}>
        <Box bg={yellow} my={20} borderRadius="xl" p={8}>
          <Grid templateRows="1fr 3fr" gap={10}>
            <GridItem as={Center}>
              <Heading as="h2" size="xl" color={primaryGreen} textAlign="center" fontFamily="'Baloo 2', sans-serif">
                A Place Where Every Dog Can Thrive
              </Heading>
            </GridItem>
            <GridItem>
              <Grid templateColumns={{ base: "1fr", md: "1fr 1fr 1fr" }} gap={10}>
                <CardContent img={Icon1} title="Proud Partners of Every Paw Matters" description="Training Tails, Leading Hearts: Together, We Fetch a Better Future!" />
                <CardContent img={Icon2} title="Our Training & Daycare Team" description="Taking every decision with a nose for what’s best for your dog." />
                <CardContent img={Icon3} title="Customer Friendly" description="Fetching the best for you: our tech-savvy platform makes booking and care as easy as a wag of the tail." />
              </Grid>
            </GridItem>
          </Grid>
        </Box>
      </Box>
      <Img src={WavyBg} zIndex={9} alt="Grass bottom decoration" position="absolute" bottom="-1" left="-10vw" right="0" minW="120vw" />
    </Box>
  );
};
