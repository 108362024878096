import React from "react";
import { Box } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";

import { BehaviouralLead } from "../components/behavioural/BehaviouralLead";
import { BehaviouralBackground } from "../components/behavioural/BehaviouralBackground";
import { BehaviouralKeySkills } from "../components/behavioural/BehaviouralKeySkills";
import { BehaviouralGallery } from "../components/behavioural/BehaviouralGallery";
import { BehaviouralDiscussion } from "../components/behavioural/BehaviouralDiscussion";

export const Behavioural = () => {
  return (
    <Box bg="white">
      <Helmet>
        <title>Behavioural Services - Muttley's Doggy Daycare</title>
        <meta name="description" content="Explore the expert behavioural services offered at Muttley's Doggy Daycare by Sarah Squire, our lead behaviourist with over 20 years of experience in dog training and behavioural assessments." />
        <meta name="keywords" content="dog behaviourist, dog training, behavioural assessments, dog obedience, dog socialisation, Muttley's Doggy Daycare" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Service",
            serviceType: "Dog Behavioural Services",
            provider: {
              "@type": "Organization",
              name: "Muttley's Doggy Daycare",
              url: "https://muttleysdoggydaycare.com",
            },
            description: "Expert dog behavioural services led by Sarah Squire, offering comprehensive behavioural assessments, training programs, and positive reinforcement techniques to address a wide range of behavioural issues.",
            areaServed: {
              "@type": "Place",
              name: "Bournemouth, Christchurch, and surrounding areas",
            },
            availableChannel: {
              "@type": "ServiceChannel",
              servicePhone: {
                "@type": "ContactPoint",
                telephone: "+44-1202-090704",
                contactType: "Customer Service",
              },
              serviceUrl: "https://muttleysdoggydaycare.com/behavioural",
            },
            offers: {
              "@type": "Offer",
              url: "https://muttleysdoggydaycare.com/behavioural",
              priceCurrency: "GBP",
              eligibleRegion: {
                "@type": "Place",
                name: "UK",
              },
            },
            additionalType: "https://muttleysdoggydaycare.com/behavioural",
            mainEntityOfPage: "https://muttleysdoggydaycare.com/behavioural",
          })}
        </script>
      </Helmet>

      <BehaviouralLead />
      <BehaviouralBackground />
      <BehaviouralKeySkills />
      <BehaviouralGallery />
      <BehaviouralDiscussion />
    </Box>
  );
};
