import { Button, Heading, Image, Text, VStack, Box, Center } from "@chakra-ui/react";
import React from "react";
import { lightGreen, orange, primaryGreen } from "../../constants";

interface CardProps {
  img: string;
  title: string;
  description: string;
  onClick: () => void;
}

export const Card: React.FC<CardProps> = ({ img, title, description, onClick }) => {
  return (
    <VStack bg="white" p={6} borderRadius="2xl" boxShadow="md" align="center" justify="space-between" spacing={4} w="100%" h="100%">
      {/* Box to control image and title height */}
      <Center w="100%" textAlign="center" flexDirection="column">
        <Image src={img} alt={title} minW="100%" minH={{ base: "15rem", md: "10rem", lg: "15rem" }} borderRadius="md" boxSize="150px" objectFit="cover" mb={4} />
        <Heading as="h2" size="md" color={lightGreen} fontFamily="'Baloo 2', sans-serif" mb={4}>
          {title}
        </Heading>
      </Center>
      {/* Description text starts consistently */}
      <Text color={primaryGreen} fontFamily="'Baloo 2', sans-serif" fontSize="md" textAlign="center" flexGrow={1}>
        {description}
      </Text>
      <Button bgColor={orange} color="white" onClick={onClick} alignSelf="center" w="full">
        Find out more
      </Button>
    </VStack>
  );
};
