import React from "react";
import { Box, Heading, Text, Grid, GridItem, Center, Image, VStack } from "@chakra-ui/react";
import PuppyGroupsImg from "../../assets/puppy-groups=2.png";
import Paw from "../../assets/paw.png";
import { lightGreen, primaryGreen } from "../../constants";

export const PuppyGroups = () => {
  return (
    <Box bg="white" as="section" py={{ base: 12, md: 20 }} position="relative">
      <Box maxW="7xl" mx="auto" px={6}>
        <Grid templateColumns={{ base: "1fr", md: "0.7fr 1fr" }} gap={20}>
          <GridItem>
            <VStack align="flex-start" justify="center" spacing={6}>
              <Heading as="h2" size="lg" color={lightGreen} fontFamily="'Baloo 2', sans-serif">
                Puppy Groups
              </Heading>
              <Heading as="h2" size="lg" color={primaryGreen} fontFamily="'Baloo 2', sans-serif">
                Providing a structured and supportive environment for young puppies to learn and grow.
              </Heading>
              <Text color="gray.700" fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
                - Small Group Sessions: Limited class sizes to ensure personalised attention for every puppy.
                <br />
                - Tailored exercises that match your puppy's developmental stage.
                <br />
                - Socialisation Opportunities: Safe, structured playtime with other puppies to develop essential social skills.
                <br />
                - Positive Reinforcement Techniques: Teaching puppies using reward-based methods that encourage good behaviour.
                <br />
                - Experienced Trainers: Led by certified trainers with a deep understanding of puppy behaviour and development.
                <br />
                - Building Basic Obedience: Introduction to essential commands such as sit, stay, and rcall.
                <br />- Confidence Building: Activities designed to reduce fear and build confidence in new environments.
              </Text>
            </VStack>
          </GridItem>
          <GridItem>
            <Center h="100%">
              <Image h="auto" w="70%" borderRadius="lg" src={PuppyGroupsImg} alt="Dog Field" />
            </Center>
          </GridItem>
        </Grid>
        <Image src={Paw} alt="Paw" position="absolute" bottom="0" right="0" w="20%" zIndex={9} />
      </Box>
    </Box>
  );
};
