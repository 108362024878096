import React, { useEffect, useRef, useState } from "react";
import { Box, Heading, Text, Grid, GridItem, Flex, VStack, Link, Image } from "@chakra-ui/react";
import { FiMail, FiPhone } from "react-icons/fi";
import { lightGreen, primaryGreen, yellow } from "../../constants";
import WavyBg from "../../assets/primary-green-wavy-bg.png";
import "mapbox-gl/dist/mapbox-gl.css";
import { GlobeMap } from "./GlobeMap";

export const ContactMap = () => {
  return (
    <Box bg="white" as="section" position="relative" height="1200px" overflow="hidden">
      <Box height="700px">
        <Grid templateColumns={{ base: "1fr", md: "1.5fr 1fr" }} height="100%" gap={0}>
          <GridItem height="100%">
            <Box height="100%" zIndex={999999}>
              <GlobeMap />
            </Box>
          </GridItem>
          <GridItem bg={lightGreen} p={8} display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
            <VStack align="flex-start" spacing={6} width="100%">
              <Heading as="h2" size="xl" fontFamily="'Baloo 2', sans-serif" color={yellow}>
                Get in Touch
              </Heading>
              <Text fontFamily="'Baloo 2', sans-serif" lineHeight={1.8} color="white">
                Feel free to reach out to us via Dog ‘n Bone or email. We're here to answer any of your questions and provide the support you need.
              </Text>

              <Flex direction="row" align="center" gap={3} mb={4}>
                <FiPhone color={yellow} size={24} />
                <Text fontFamily="'Baloo 2', sans-serif" lineHeight={1.8} color="white">
                  01202 090704
                </Text>
              </Flex>
              <Flex direction="row" align="center" gap={3}>
                <FiMail color={yellow} size={24} />
                <Text fontFamily="'Baloo 2', sans-serif" lineHeight={1.8} color="white">
                  <Link href="mailto:info@muttleysdoggydaycare.com" color="white">
                    info@muttleysdoggydaycare.com
                  </Link>
                </Text>
              </Flex>
            </VStack>
          </GridItem>
        </Grid>
      </Box>
      <Image src={WavyBg} alt="Grass bottom decoration" position="absolute" bottom="-1" left="-10vw" right="0" minW="120vw" zIndex={0} />
    </Box>
  );
};
