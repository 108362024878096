import React from "react";
import { Box, Heading, Text, Grid, GridItem, Center, Image, VStack } from "@chakra-ui/react";
import LandingPageAboutImg from "../../assets/landing-page-about.png";
import Paw from "../../assets/paw.png";
import DaycareFacilitiesImg from "../../assets/daycare-facilities-beds.png";
import { lightGreen, primaryGreen } from "../../constants";

export const DaycareFacilities = () => {
  return (
    <Box bg="white" as="section" py={{ base: 12, md: 20 }} position="relative">
      <Box maxW="7xl" mx="auto" px={6}>
        <Grid templateColumns={{ base: "1fr", md: "0.7fr 1fr" }} gap={20}>
          <GridItem>
            <VStack align="flex-start" justify="center" spacing={6}>
              <Heading as="h2" size="lg" color={lightGreen} fontFamily="'Baloo 2', sans-serif">
                Our Facilities
              </Heading>
              <Heading as="h2" size="lg" color={primaryGreen} fontFamily="'Baloo 2', sans-serif">
                At our Doggy Daycare, your dog will have an exciting and enriching day in a safe, spacious environment tailored for both play and socialisation.
              </Heading>
              <Text color="gray.700" fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
                Our expansive indoor and outdoor areas provide the perfect setting for your dog to run, play, and interact with other friendly dogs, all under the attentive care of our trained and experienced staff.
                <br />
                <br />
                We offer a variety of activities designed to keep your dog engaged and happy, from energetic group games to quiet rest periods, ensuring a balanced day filled with both fun and relaxation.
              </Text>
            </VStack>
          </GridItem>
          <GridItem>
            <Center h="100%">
              <Image h="auto" w="70%" borderRadius="lg" src={DaycareFacilitiesImg} alt="Dog Field" />
            </Center>
          </GridItem>
        </Grid>
        <Image src={Paw} alt="Paw" position="absolute" bottom="0" right="0" w="20%" zIndex={9} />
      </Box>
    </Box>
  );
};
