import React from "react";
import { Box, Heading, Text, Grid, GridItem, Image, Flex, Button, Link, Center } from "@chakra-ui/react";
import { collarLink, lightGreen, orange, primaryGreen, yellow } from "../../constants";

import DogLeft from "../../assets/about-review-dog-left.png";
import DogRight from "../../assets/about-review-dog-right.png";
import Quotes from "../../assets/quotes.png";
import WavyBg from "../../assets/primary-green-wavy-bg.png";

export const DaycareMembershipFees = () => {
  return (
    <Box bg={lightGreen} as="section" py={{ base: 12, md: 20 }} position="relative" overflow="hidden">
      <Box maxW="7xl" mx="auto" px={6} mb={{ base: 10, md: 10, lg: 15, xl: 400 }}>
        <Box bg={yellow} m={{ base: 4, md: 10, lg: 20 }} borderRadius="xl" p={{ base: 6, md: 8 }} position="relative" overflow="hidden">
          <Grid templateColumns={{ base: "1fr", lg: "1fr 3fr 1fr" }} gap={{ base: 4, md: 10 }} alignItems="center">
            <GridItem display={{ base: "none", lg: "block" }}>
              <Image src={DogLeft} alt="Dog Left" position="absolute" bottom="40" left="0" transform="translateY(50%)" w="100%" maxW="150px" zIndex={1} />
            </GridItem>
            <GridItem alignItems="center">
              <Flex direction="column" align="center" position="relative">
                <Image src={Quotes} alt="Quotes" w="30px" zIndex={1} mb={4} />
                <Heading as="h2" size="lg" color={primaryGreen} textAlign="center" fontFamily="'Baloo 2', sans-serif">
                  Membership and Fees
                </Heading>
              </Flex>
              <Text color={primaryGreen} textAlign="center" fontFamily="'Baloo 2', sans-serif" lineHeight={1.6} mt={4}>
                Click here to be transferred to our services and booking platform, powered by Collar.
                <br />
                <br />
                Here you will see all our services, membership options as well as being able to sign up or book your ‘FREE’ Daycare Meet & Greet
              </Text>
              <Center mt={5}>
                <Button size="lg" as={Link} href={collarLink} bg={orange} color="white">
                  Book now
                </Button>
              </Center>
            </GridItem>

            <GridItem display={{ base: "none", lg: "block" }}>
              <Image src={DogRight} alt="Dog Right" position="absolute" bottom="20" right="0" transform="translateY(50%)" w="100%" maxW="150px" zIndex={1} />
            </GridItem>
          </Grid>
        </Box>
      </Box>

      <Image src={WavyBg} alt="Grass bottom decoration" position="absolute" bottom="-1" left="-10vw" right="0" minW="120vw" zIndex={0} />
    </Box>
  );
};
