import React from "react";
import { Box, Heading, Text, Grid, GridItem, Center, Image, VStack } from "@chakra-ui/react";
import { FaPaw } from "react-icons/fa";

import LightGreenWavyBottom from "../../assets/light-green-wavy-bottom.png";
import Paw from "../../assets/paw.png";
import WhoWeAre from "../../assets/about-who-we-are.png";
import { lightGreen, yellow } from "../../constants";

export const AboutWhoWeAre = () => {
  return (
    <Box bgColor="white">
      <Box bg={lightGreen} as="section" py={{ base: 12, md: 20 }} pos="relative">
        <Box maxW="7xl" mx="auto" px={6}>
          <Grid templateColumns={{ base: "1fr", md: "0.7fr 1fr" }} gap={20}>
            <GridItem>
              <VStack align="flex-start" justify="center" spacing={6}>
                <Heading as="h2" size="lg" color={yellow} fontFamily="'Baloo 2', sans-serif">
                  Who are we?
                </Heading>
                <Heading as="h2" size="xl" color="white" fontFamily="'Baloo 2', sans-serif">
                  And why we do what we do
                </Heading>
                <Text color="white" fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
                  At Muttley's, we are proud to support and be the designated dog behaviourists for Every Paw Matters, a charity devoted to improving the lives of dogs in need. <br /> We take pride in being a part of their mission to ensure every dog gets the care and attention they deserve. Partnering with Every Paw Matters aligns perfectly with our commitment to provide compassionate and effective dog training, and we’re thrilled to support their incredible work.
                </Text>
              </VStack>
            </GridItem>
            <GridItem>
              <Center h="100%">
                <Image width="100%" height="auto" borderRadius="lg" aspectRatio={1} src={WhoWeAre} alt="Dog Field" />
              </Center>
            </GridItem>
          </Grid>
          <Image src={Paw} alt="Paw" position="absolute" bottom="0" right="0" w="20%" zIndex={9} />
        </Box>
      </Box>
      <Image src={LightGreenWavyBottom} alt="Wavy Bottom Decoration" />
    </Box>
  );
};
