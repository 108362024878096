import React from "react";
import { Box, Heading, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Text, Flex, Icon, useColorModeValue } from "@chakra-ui/react";
import { FiChevronDown } from "react-icons/fi";

import { collarLink, orange, primaryGreen } from "../constants";
import { Helmet } from "react-helmet-async";
export const faqContent = [
  {
    category: "Health and Safety",
    questions: [
      {
        question: "What happens if my dog gets sick or injured while at the daycare?",
        answer: "Your dog’s health and safety are our top priority. If your dog becomes sick or injured, we will immediately contact you and your emergency contact. If necessary, we will take your dog to our veterinary partner for treatment. Rest assured, we will keep you updated throughout the process.",
      },
      {
        question: "How do you handle behavioural or aggressive behaviour?",
        answer: "We have a clear procedure for managing behavioural or aggressive behaviour. If a dog exhibits aggressive tendencies, our experienced staff will assess the situation and may separate the dog from the group to ensure the safety of all dogs and staff. We will then discuss the behaviour with you and suggest possible solutions or training to address the issue.",
      },
      {
        question: "What is your experience in dealing with behavioural correction?",
        answer: "Our team is highly experienced in behavioural correction and positive reinforcement training. We have extensive training and years of experience working with dogs of all breeds and temperaments. We tailor our approach to meet the unique needs of each dog, ensuring they have a positive and rewarding experience at our daycare.",
      },
    ],
  },
  {
    category: "Enrolment and Requirements",
    questions: [
      {
        question: "What is your enrolment and assessment process?",
        answer: "Before joining our daycare, every dog must go through an enrolment and assessment process. This is not a test but a way for us to check your dog’s suitability and ensure they are a perfect fit for our centre. The assessment helps us understand your dog’s temperament and behaviour so we can provide the best care possible. To see our simple daycare enrolment process, please click on this link.",
      },
    ],
  },
  {
    category: "Fees and Payments",
    questions: [
      {
        question: "What are your pricing and membership options?",
        answer: `For our most up-to-date pricing and membership options for regular users, please visit our booking and payment platform by following <a href="${collarLink}"> this </a> link.`,
      },
    ],
  },
];
export const FAQPage: React.FC = () => {
  const bgColor = useColorModeValue("gray.100", "gray.800");
  const buttonHoverBg = useColorModeValue("gray.200", "gray.600");
  const panelTextColor = useColorModeValue("gray.800", "gray.200");

  return (
    <Box bg="white">
      <Helmet>
        <title>Frequently Asked Questions - Muttley's Doggy Daycare</title>
        <meta name="description" content="Find answers to common questions about Muttley's Doggy Daycare, including information on our services, enrolment, and safety protocols." />
        <meta name="keywords" content="doggy daycare FAQ, pet care questions, dog services, Muttley's Doggy Daycare" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: faqContent.flatMap((category) =>
              category.questions.map((faq) => ({
                "@type": "Question",
                name: faq.question,
                acceptedAnswer: {
                  "@type": "Answer",
                  text: faq.answer,
                },
              }))
            ),
          })}
        </script>
      </Helmet>

      {/* FAQ Section */}
      <Box as="section" py={10} px="6" fontWeight="600" bg="white" color="white" id="faq">
        <Box maxW="1000px" mx="auto">
          <Accordion allowToggle>
            {faqContent.map((category, index) => (
              <Box key={index} mb="12">
                <Heading fontFamily={`'Baloo 2', sans-serif`} as="h3" size="lg" mb="4" color={primaryGreen}>
                  {category.category}
                </Heading>
                {category.questions.map((faq, idx) => (
                  <AccordionItem key={idx} border="none" mb={5}>
                    {({ isExpanded }) => (
                      <>
                        <h2>
                          <AccordionButton _expanded={{ bg: orange, color: "white" }} _hover={{ bg: buttonHoverBg }} bg={orange} borderRadius="md" boxShadow="md" mb={5} p="6">
                            <Flex flex="1" textAlign="left" align="center">
                              <Text fontFamily={`'Baloo 2', sans-serif`} fontSize={"medium"} fontWeight="700">
                                {faq.question}
                              </Text>
                            </Flex>
                            <Icon as={FiChevronDown} transform={isExpanded ? "rotate(180deg)" : "rotate(0deg)"} transition="transform 0.3s" />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4} lineHeight={2} fontSize="medium" bg={bgColor} borderRadius="md" boxShadow="sm">
                          <Text fontFamily={`'Baloo 2', sans-serif`} color={panelTextColor}>
                            <span dangerouslySetInnerHTML={{ __html: faq.answer }} />
                          </Text>
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                ))}
              </Box>
            ))}
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
};
