import React from "react";
import { Box, Heading, VStack, HStack, Link, Grid, GridItem, Center, Image } from "@chakra-ui/react";
import { FaPhone, FaEnvelope, FaFacebook, FaInstagram, FaTwitter, FaLinkedin, FaYoutube, FaLinkedinIn } from "react-icons/fa";
import OrangePaw from "../../assets/orange-paw.png";
import { facebookLink, instagramLink, lightGreen, orange, primaryGreen } from "../../constants";

export const ContactFollowUs = () => {
  const iconProps = {
    size: 30,
    color: "white",
    borderRadius: "full",
    bg: orange,
    p: 2,
  };

  return (
    <Box bg="white" as="section" my={{ base: 12, md: 20, lg: 40 }} position="relative">
      <Box maxW="7xl" mx="auto" px={6}>
        <Grid templateColumns={{ base: "1fr", md: "2fr 1fr" }} gap={20}>
          <GridItem>
            <VStack align="flex-start" justify="center" spacing={6}>
              <Heading as="h2" size="lg" color={lightGreen} fontFamily="'Baloo 2', sans-serif">
                Follow Us
              </Heading>
              <Heading as="h2" size="lg" color={primaryGreen} fontFamily="'Baloo 2', sans-serif">
                Stay updated with our latest news, tips, and adorable photos of our guests by following us:
              </Heading>
              <HStack spacing={4}>
                <Link href={instagramLink} target="_blank" rel="noopener noreferrer">
                  <Box {...iconProps}>
                    <FaInstagram />
                  </Box>
                </Link>
                <Link href={facebookLink} target="_blank" rel="noopener noreferrer">
                  <Box {...iconProps}>
                    <FaFacebook />
                  </Box>
                </Link>
              </HStack>
            </VStack>
          </GridItem>
          <GridItem>
            <Center h="100%">
              <Image src={OrangePaw} alt="Paw" position="absolute" bottom="0" right="5" w="15%" zIndex={9} />
            </Center>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
};
