import React from "react";
import { Box, Heading, Text, Grid, GridItem, Center, Image, VStack } from "@chakra-ui/react";

import GreenPaw from "../../assets/green-paw.png";
import OrangePaw from "../../assets/orange-paw.png";
import Facilities from "../../assets/woods.png";
import { lightGreen, primaryGreen } from "../../constants";

export const AboutFacilities = () => {
  return (
    <Box bg="white" as="section" py={{ base: 12, md: 20 }} pos="relative">
      <Box maxW="7xl" mx="auto" px={6}>
        <Grid zIndex={2} templateColumns={{ base: "1fr", md: "0.7fr 1fr" }} gap={20}>
          <GridItem>
            <VStack align="flex-start" justify="center" spacing={6}>
              <Heading as="h2" size="lg" color={lightGreen} fontFamily="'Baloo 2', sans-serif">
                Our Facilities
              </Heading>
              <Heading as="h2" size="lg" color={primaryGreen} fontFamily="'Baloo 2', sans-serif">
                Muttley's Doggy Daycare is sat in over 11 acres with over 1000 sqm of indoor activity space
              </Heading>
              <Text color="black" fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
                Designed to keep dogs engaged and entertained regardless of the weather.
                <br />
                <br />
                Our facility includes designated breakout and rest areas, allowing dogs to relax and unwind in a comfortable and safe environment.
                <br />
                <br />
                Outside, we offer a fully secure play-zone where dogs can explore and play freely. Additionally, we have two extensive secure exercise paddocks enclosed with high fencing to ensure the utmost safety while your dog enjoys their outdoor adventures.
              </Text>
            </VStack>
          </GridItem>
          <GridItem>
            <Center h="100%">
              <Image h="100%" borderRadius="lg" aspectRatio={1} src={Facilities} alt="Sarah Squire" zIndex={2} />
            </Center>
          </GridItem>
        </Grid>
        <Image src={GreenPaw} alt="Green Paw" position="absolute" top="0" right="0" w="10%" zIndex={1} />
        <Image src={OrangePaw} alt="Orange Paw" position="absolute" bottom="-10" left="0" w="10%" zIndex={1} />
      </Box>
    </Box>
  );
};
