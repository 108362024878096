import { Box, Button, Heading, Text, Link, VStack, Container, Center } from "@chakra-ui/react";

import GreenWavyBg from "../assets/green-wavy-bg.png";
import { orange, collarLink, primaryGreen, lightGreen } from "../constants";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { LandingPageHero } from "../components/landingPage/LandingPageHero";

export const Hero = () => {
  const [pageTitle, setPageTitle] = useState<string>("Welcome to Muttley's Doggy Daycare");
  const [bgColor, setBgColor] = useState<string>(lightGreen);

  const location = useLocation();

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setPageTitle("Play, Paws, Repeat – The Ultimate Daycare for Happy Dogs");
        setBgColor(lightGreen);
        break;
      case "/about":
        setPageTitle("The ‘heart’ of Muttley’s");
        setBgColor(lightGreen);

        break;
      case "/faqs":
        setPageTitle("FAQs");
        setBgColor("white");
        break;
      case "/services":
        setPageTitle("Our Services");
        setBgColor(lightGreen);
        break;
      case "/behavioural":
        setPageTitle("Behavioural and Training");
        setBgColor("white");

        break;
      case "/daycare":
        setPageTitle("Doggy Daycare");
        setBgColor("white");

        break;
      case "/puppies":
        setPageTitle("Puppies");
        setBgColor("white");

        break;
      case "/contact":
        setPageTitle("Toss us a Bone and Reach Out");
        setBgColor("white");
        break;
      case "/terms-and-conditions":
        setPageTitle("Terms and Conditions");
        setBgColor("white");
        break;
      default:
        setPageTitle("Welcome to Muttley's Doggy Daycare");
    }
  }, [location.pathname]);

  if (location.pathname === "/") {
    return <LandingPageHero />;
  }

  return (
    <Box position="relative" bgColor={bgColor}>
      <Box as="section" h={{ base: "40vh", lg: "60vh" }} background={`url(${GreenWavyBg})`} backgroundSize="cover" backgroundPosition="center" backgroundRepeat="no-repeat" position="relative">
        <Container maxW="7xl" h="full" position="relative" zIndex="2">
          <Center h="full" flexDirection="column" textAlign="center">
            <Heading mt="10%" size={{ base: "lg", md: "2xl" }} fontWeight="bold" fontFamily={`'Baloo 2', sans-serif`} color="white" textTransform="uppercase" mb="8">
              {pageTitle}
            </Heading>
            {/* <Button
              bg={orange}
              color="white"
              onClick={() => {
                window.open(collarLink, "_blank");
              }}
            >
              Book Here
            </Button> */}
          </Center>
        </Container>
      </Box>
    </Box>
  );
};
