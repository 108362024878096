import React from "react";
import { Box, Heading, Text, VStack } from "@chakra-ui/react";

export const TermsAndConditionsPage = () => {
  return (
    <Box bg="white" as="section" py={12} px={6}>
      <Box maxW="7xl" mx="auto">
        <VStack align="flex-start" spacing={8}>
          <Heading as="h1" size="2xl" fontFamily="'Baloo 2', sans-serif">
            Muttley's Dogs Terms & Conditions
          </Heading>

          {/* General Terms and Conditions */}
          <Heading as="h2" size="lg" fontFamily="'Baloo 2', sans-serif">
            1. General Terms and Conditions
          </Heading>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.1 Acceptance of Terms:</strong> By enrolling a dog, allowing a dog to attend, or accepting services from Muttley's Dogs and its staff, the Client agrees to these Terms and Conditions.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.2 Guardian Role:</strong> In the Client’s absence, Muttley's Dogs may act as a guardian for the Client’s dog, performing necessary actions to maintain the dog’s health. In emergencies, Muttley's Dogs is authorized to make critical healthcare decisions in collaboration with partnered vets if the Client cannot be reached.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.3 Notice of Cancellation:</strong> Muttley's Dogs requires one month’s notice for membership service cancellations. The Client must provide this notice or pay the equivalent amount. Cancellations can be made at any time; there is no minimum contract term.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.4 Fixed Monthly Fee:</strong> Muttley's Dogs charges a fixed monthly fee for daycare, calculated over 50 operational weeks and averaged into 12 monthly payments. Additionally, there is an option to book ad-hoc days as a member. The monthly fee is payable even if the Client's dog does not attend due to holidays, sickness, or any other reason.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.5 Service Day Swaps and Additional Days:</strong> Clients starting services from 1st September 2024 can swap regular service days with advance notice and subject to availability. Swapped days must be used within 2 months, are non-refundable, and cannot be exchanged for cash. Extra days can be requested and charged at the agreed "Ad Hoc Day" rate.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.6 Payment Terms:</strong> Payment for services is due at the time of booking via the booking and payment platform or, in the event of agreed cash payment, at the time of the service provision. All payments must be made in full without set-off, counterclaim, deduction, or withholding. Muttley's Dogs may offset any amount owed by the Client against any amount payable to the Client. Upon contract termination, the Client must immediately pay all outstanding invoices and
            interest. For unbilled services, Muttley's Dogs will issue an invoice payable upon receipt.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.7 Annual Price Adjustment:</strong> Service charges may change in line with delivery costs, with at least 30 days' notice provided via email.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.8 Limitation of Liability:</strong> The Client is responsible for costs due to veterinary services or other expenses resulting from damage, accidents, or sickness caused to or by their dog. Muttley's Dogs’ liability is not limited or excluded for:
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            - Death or personal injury caused by negligence.
            <br />
            - Fraud or fraudulent misrepresentation.
            <br />- Breach of terms implied by section 2 of the Supply of Goods and Services Act 1982 or other applicable law.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            Subject to the above, Muttley's Dogs is not liable for:
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            - Loss of profits.
            <br />
            - Loss of sales or business.
            <br />
            - Loss of agreements or contracts.
            <br />
            - Loss of anticipated savings.
            <br />
            - Loss or damage to goodwill.
            <br />- Any indirect or consequential loss.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            Muttley's Dogs’ total liability is limited to £1000 or the total fees paid under the contract, whichever is lower. Terms implied by sections 3 to 5 of the Supply of Goods and Services Act 1982 are excluded to the fullest extent permitted by law. This clause survives contract termination. The Client agrees to insure their dog under a pet insurance policy and their home and property under a house and contents insurance policy, notifying insurers of the daycare arrangement.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.9 Transport of Dogs:</strong> The Client agrees Muttley's Dogs is not liable for death or injury to their dog in a motor vehicle accident.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.10 Holding of Keys:</strong> Muttley's Dogs may hold the Client’s home keys, with the Client responsible for arranging insurance cover.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.11 Off-Lead Environment:</strong> The Client permits Muttley's Dogs to walk their dog off the lead and consents to their dog playing with other dogs in a secure field environment. Activities occur within Muttley's Dogs' premises.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.12 Dog Grouping:</strong> Muttley's Dogs groups dogs based on age, play style, temperament, and size for the best experience.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.13 Aggressive Behaviour:</strong> The Client confirms their dog has no record of aggressive or anti-social behaviour and has disclosed any traits making their dog unsuitable for socialising.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.14 Unsuitable Dogs:</strong> Muttley's Dogs reserves the right to cancel bookings if a dog is deemed unsuitable.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.15 Neutering and Seasons:</strong> Male dogs over one year old must be neutered to attend. Females in season generally cannot attend for at least 4 weeks.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.16 Vaccinations:</strong> The Client agrees to keep their dog up to date on vaccinations, de-worming, and de-fleaing.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.17 Parasite Treatment:</strong> If fleas, ticks, or lice are noticed, the Client will be contacted for veterinary advice. Muttley's Dogs will not administer medication without consent.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.18 Emergency Treatment:</strong> Muttley's Dogs' designated emergency vet is authorized to treat in emergencies. Consent for euthanasia must be given by the owner or main contact if recommended by a vet. If not contactable, veterinary advice will be followed with records kept of euthanasia and the veterinarian's identity.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.19 Medication Administration:</strong> Medicines will be stored, used, and disposed of according to the manufacturer's instructions and only administered with the Client’s consent.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.20 Crate Use:</strong> Crates may be used for rest breaks, feeding, or in exceptional circumstances for safety.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.21 Food Supply:</strong> Muttleys Dogs does not supply dog food. If the Client provides food, it will be fed in isolation from other dogs.
          </Text>
          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.22 Minimum Age:</strong> All dogs must be over 12 weeks old to attend.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.23 Behaviour Training:</strong> Muttley's Dogs may use behaviour modification techniques for training purposes.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.24 Data Protection:</strong> Client data will be used and stored following GDPR regulations.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.25 Termination:</strong> Either party can terminate services with one month’s notice.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.26 Payment for Services:</strong> Payments for services rendered must be made in full at the time of booking or service provision. Unpaid invoices will accrue interest at 8% above the Bank of England base rate.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.27 Delay in Payment:</strong> If payment is not received within the specified time, Muttley's Dogs reserves the right to suspend or terminate services.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.28 Dog Illness/Injury:</strong> The Client must inform Muttley's Dogs immediately if their dog shows signs of illness or injury. The Client must keep their dog home if unwell or recovering from surgery or an injury.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.29 Dog Aggression:</strong> The Client must inform Muttley's Dogs if their dog shows any aggressive behavior. Muttley's Dogs may require the Client’s dog to attend a behavior consultation with a qualified behaviorist at the Client’s expense.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.30 Exclusion of Dogs:</strong> Muttley's Dogs reserves the right to exclude any dog that, in its opinion, poses a risk to the safety of other dogs or people.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.31 Weather Conditions:</strong> Muttley's Dogs may alter the exercise or activity program based on weather conditions to ensure the safety and comfort of the dogs.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.32 Dog Possessions:</strong> The Client is responsible for providing appropriate collars, leads, and coats. Muttley's Dogs is not liable for any loss or damage to these possessions.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.33 Governing Law:</strong> The contract and any related disputes are governed by the law of England and Wales.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>1.34 Jurisdiction:</strong> The courts of England and Wales have exclusive jurisdiction over disputes related to the contract.
          </Text>

          {/* Additional Clauses for Individual Dog Training Lessons */}
          <Heading as="h2" size="lg" fontFamily="'Baloo 2', sans-serif">
            2. Additional Clauses for Individual Dog Training Lessons
          </Heading>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>2.1 Owner Responsibility During Training:</strong> The owner acknowledges that during individual training sessions they remain fully responsible for their dog. Muttley's Dogs and its trainers are not liable for any injury, loss, or damage caused by the dog during the training session.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>2.2 Training Effectiveness:</strong> The owner acknowledges that the effectiveness of training depends on the owner's commitment to practicing the techniques taught by Muttley's Dogs outside of the training sessions.
          </Text>

          {/* Group Classes */}
          <Heading as="h2" size="lg" fontFamily="'Baloo 2', sans-serif">
            3. Group Classes
          </Heading>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>3.1 Owner Responsibility:</strong> During group classes, the owner remains fully responsible for their dog. Muttley's Dogs and its trainers are not liable for any injury, loss, or damage caused by the dog during the group class.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>3.2 Attendance:</strong> The owner agrees to attend all scheduled group classes. Muttley's Dogs is not responsible for providing makeup classes for missed sessions.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>3.3 Training Effectiveness:</strong> The owner acknowledges that the effectiveness of training depends on the owner's commitment to practicing the techniques taught by Muttley's Dogs outside of the group classes.
          </Text>

          {/* Miscellaneous */}
          <Heading as="h2" size="lg" fontFamily="'Baloo 2', sans-serif">
            4. Miscellaneous
          </Heading>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>4.1 Waiver:</strong> No waiver by Muttley's Dogs of any breach of the contract by the Client shall be considered as a waiver of any subsequent breach of the same or any other provision.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>4.2 Severability:</strong> If any provision of the contract is held by any competent authority to be invalid or unenforceable in whole or in part, the validity of the other provisions of the contract and the remainder of the provision in question shall not be affected.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>4.3 Assignment:</strong> The Client shall not assign, transfer, or charge any of its rights or obligations under the contract without the prior written consent of Muttley's Dogs.
          </Text>

          <Text fontSize="lg" color="gray.700" lineHeight={1.8}>
            <strong>4.4 Entire Agreement:</strong> The contract constitutes the entire agreement between the parties and supersedes all prior agreements, representations, and understandings.
          </Text>
        </VStack>
      </Box>
    </Box>
  );
};
