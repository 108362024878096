import React from "react";
import { Box, Heading, Text, Image, VStack, HStack, Flex } from "@chakra-ui/react";
import Paw from "../../assets/paw.png";
import { lightGreen, primaryGreen, yellow } from "../../constants";
import { FaPaw } from "react-icons/fa";

const Icon = FaPaw;

const features = [
  { title: "Comprehensive Behavioural Assessments:", text: "Conducts in-depth evaluations of dogs' behaviours to identify root causes of issues and develop tailored intervention plans." },
  { title: "Training Programs:", text: "Designs and implements customised training programs to address a wide range of behavioural problems, including aggression, anxiety, and obedience issues." },
  { title: "Owner Education:", text: "Provides thorough training and education to dog owners, empowering them with the knowledge and skills to effectively manage and improve their pets' behaviour." },
  { title: "Positive Reinforcement Techniques:", text: "Specialises in positive reinforcement methods, ensuring humane and effective behaviour modification." },
  { title: "Puppy Socialisation Classes:", text: "Leads classes aimed at socialising puppies and older dogs to prevent future behavioural problems and promote healthy development." },
];

export const BehaviouralBackground = () => {
  return (
    <Box bg="white" as="section" py={{ base: 12, md: 20 }} position="relative">
      <Box maxW="7xl" mx="auto" px={6} mb={60}>
        <VStack align="flex-start" justify="center" spacing={6}>
          <Heading as="h2" size="lg" color={primaryGreen} fontFamily="'Baloo 2', sans-serif">
            This background uniquely equips her to understand and address complex behavioural issues in both dogs and people.
          </Heading>

          {features.map((feature, index) => (
            <HStack key={index} align="flex-start" spacing={3}>
              <Icon color={yellow} size="1.5em" />
              <Text fontSize="md" color="gray.800" fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
                <Text fontSize="md" color={lightGreen} fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
                  {feature.title}
                </Text>
                {feature.text}
              </Text>
            </HStack>
          ))}
        </VStack>

        <Image src={Paw} alt="Paw" position="absolute" bottom="0" right="0" w="20%" zIndex={9} />
      </Box>
    </Box>
  );
};
