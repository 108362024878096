import React from "react";
import { Box, Heading, Grid, GridItem } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { lightGreen } from "../../constants";
import { Card } from "../shared/Card";
import Daycare from "../../assets/daycare.jpeg";
import Behavioural from "../../assets/behavioural-training.jpeg";
import Puppies from "../../assets/puppies.jpeg";

export const LandingPageHelp = () => {
  const navigate = useNavigate();

  return (
    <Box bg={lightGreen} as="section" py={{ base: 12, md: 20 }} position="relative">
      <Box maxW="7xl" mx="auto" px={6}>
        <Heading as="h3" size="md" color="white" textAlign="left" fontFamily={`'Baloo 2', sans-serif`} mb={4}>
          How can we help
        </Heading>
        <Heading as="h2" size="lg" mb={12} color="white" textAlign="left" fontFamily={`'Baloo 2', sans-serif`}>
          Our Services
        </Heading>
        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr", lg: "1fr 1fr 1fr" }} gap={10}>
          <GridItem>
            <Card img={Daycare} title="Doggy Daycare" description="Safe, fun playtime with expert care, leaving your pup happy and well-exercised!" onClick={() => navigate("/daycare")} />
          </GridItem>
          <GridItem>
            <Card img={Behavioural} title="Behavioural and Training" description="Expert dog training, correcting bad behavior in dogs while giving owners confidence and control." onClick={() => navigate("/behavioural")} />
          </GridItem>
          <GridItem>
            <Card img={Puppies} title="Puppies" description="Empowering owners with expert guidance for confident, well-rounded, and socialised puppies during their critical growth phase." onClick={() => navigate("/puppies")} />
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
};
