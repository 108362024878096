import React from "react";
import { Box } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";
import "../main.css";

import { AboutWhoWeAre } from "../components/about/AboutWhoWeAre";
import { AboutPackLeader } from "../components/about/AboutPackLeader";
import { AboutFacilities } from "../components/about/AboutFacilities";
import { AboutLocation } from "../components/about/AboutLocation";
import { AboutReview } from "../components/about/AboutReview";
import { facebookLink, instagramLink } from "../constants";

export const AboutUsPage = () => {
  return (
    <Box bg="gray.50" id="about">
      <Helmet>
        <title>About Us - Muttley's Doggy Daycare</title>
        <meta name="description" content="Learn about Muttley's Doggy Daycare, our founder, our state-of-the-art facilities, our convenient location, and our commitment to providing exceptional care for your canine companions." />
        <meta name="keywords" content="doggy daycare, dog care, dog facilities, dog training, Bournemouth, Christchurch" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Muttley's Doggy Daycare",
            url: "https://muttleysdoggydaycare.com",
            logo: "https://muttleysdoggydaycare.com/logo.png",
            sameAs: [facebookLink, instagramLink],
            description: "Our Founder, Sarah Squire, is both a trained psychologist and renowned dog behaviourist, bringing a wealth of experience in working with both people and animals.",
            founder: {
              "@type": "Person",
              name: "Sarah Squire",
              description: "Sarah Squire is a highly experienced dog behaviourist with over 20 years of experience, providing behavioral assessments, one-to-one training, and working extensively with dog charities.",
              jobTitle: "Founder",
              worksFor: {
                "@type": "Organization",
                name: "Muttley's Doggy Daycare",
              },
            },
            address: {
              "@type": "PostalAddress",
              streetAddress: "Home Farm Hurn Christchurch",
              addressLocality: "Christchurch",
              postalCode: "BH23 6AE",
              addressCountry: "UK",
            },
            contactPoint: {
              "@type": "ContactPoint",
              telephone: "+44-1202-090704",
              contactType: "Customer Service",
            },
            potentialAction: {
              "@type": "ViewAction",
              target: ["https://muttleysdoggydaycare.com/about"],
              name: "Learn more about us",
            },
            serviceArea: {
              "@type": "Place",
              name: "Bournemouth, Christchurch, and surrounding areas",
            },
            department: [
              {
                "@type": "Organization",
                name: "Training and Behavioural Services",
                description: "Expert dog training and behavioral services tailored to your dog's needs.",
              },
              {
                "@type": "Organization",
                name: "Daycare Facilities",
                description: "State-of-the-art facilities ensuring your dog has a fun and safe experience.",
              },
            ],
            review: {
              "@type": "Review",
              reviewRating: {
                "@type": "Rating",
                ratingValue: "5",
                bestRating: "5",
              },
              author: {
                "@type": "Person",
                name: "Maddie Edwards",
              },
              reviewBody: "Muttleys has been absolutely phenomenal in giving Dodger, my Labrador, and me the knowledge and techniques to work on getting the results I always hoped for.",
            },
          })}
        </script>
      </Helmet>

      <AboutWhoWeAre />
      <AboutPackLeader />
      <AboutFacilities />
      <AboutLocation />
      <AboutReview />
    </Box>
  );
};
