import React from "react";
import { Box, Heading, Grid, GridItem, Image } from "@chakra-ui/react";

import Paw from "../../assets/paw.png";
import DaycareImg from "../../assets/daycare.jpeg";
import BarnImg from "../../assets/barn.jpeg";
import BackBarnImg from "../../assets/barn-grid-image.png";
import FrontSignImg from "../../assets/muttleys-front-sign.png";
import DaycareBedsImg from "../../assets/daycare-beds.png";
import DaycareGrassImg from "../../assets/daycare-grass.png";
import FounderImg from "../../assets/founder.jpg";
import PuppiesRunning from "../../assets/puppies-running.png";
import Rescue from "../../assets/rescue.jpeg";
import Obedience from "../../assets/obedience.jpeg";
import DogSatLooking from "../../assets/dog-sat-looking.png";
import PuppyRunningGrass from "../../assets/puppy-running-grass.png";
import DogSatInFlowers from "../../assets/dog-sat-in-flowers.png";
import FieldLarge from "../../assets/field-large.png";
import { primaryGreen } from "../../constants";

export const PuppyGallery = () => {
  const gap = 1.25; // 1rem gap

  // Calculate heights in rem while considering the gap
  const largeItemHeight = `calc(27rem - ${gap}rem)`; // 18rem - gap for the two-row items
  const smallItemHeight = `calc(18rem - ${gap}rem)`; // 12rem - gap for the three-row items

  return (
    <Box bg={primaryGreen} as="section" py={{ base: 12, md: 20 }} position="relative">
      <Box maxW="7xl" mx="auto" px={6}>
        <Heading as="h2" size="xl" color="white" fontFamily="'Baloo 2', sans-serif" textAlign="center" mb={10}>
          Gallery
        </Heading>
        <Grid templateColumns={{ base: "1fr", md: "1.75fr 3fr" }} gap={`${gap}rem`}>
          <GridItem as={Grid} templateRows="repeat(2, 1fr)" gap={`${gap}rem`}>
            <GridItem height={largeItemHeight}>
              <Image objectFit="cover" h="100%" w="100%" borderRadius="lg" src={PuppiesRunning} alt="Dog Field" />
            </GridItem>
            <GridItem height={largeItemHeight}>
              <Image objectFit="cover" h="100%" w="100%" borderRadius="lg" src={Obedience} alt="Front Sign" />
            </GridItem>
          </GridItem>
          <GridItem as={Grid} templateRows="repeat(3, 1fr)" gap={`${gap}rem`}>
            <GridItem height={smallItemHeight}>
              <Image objectFit="cover" h="100%" w="100%" borderRadius="lg" src={Rescue} alt="Back of Barn" />
            </GridItem>
            <GridItem height={smallItemHeight}>
              <Image objectFit="cover" h="100%" w="100%" borderRadius="lg" src={FieldLarge} alt="Barn" />
            </GridItem>
            <Grid templateColumns="repeat(3, 1fr)" gap={`${gap}rem`}>
              <GridItem height={smallItemHeight}>
                <Image objectFit="cover" h="100%" w="100%" borderRadius="lg" src={DogSatLooking} alt="Daycare Beds" />
              </GridItem>
              <GridItem height={smallItemHeight}>
                <Image objectFit="cover" h="100%" w="100%" borderRadius="lg" src={PuppyRunningGrass} alt="Daycare Grass" />
              </GridItem>
              <GridItem height={smallItemHeight}>
                <Image objectFit="cover" h="100%" w="100%" borderRadius="lg" src={DogSatInFlowers} alt="Founder" />
              </GridItem>
            </Grid>
          </GridItem>
        </Grid>
        <Image objectFit="cover" src={Paw} alt="Paw" position="absolute" bottom="0" right="0" w="20%" zIndex={9} />
      </Box>
    </Box>
  );
};
