import React from "react";
import { Box, Heading, Text, Grid, GridItem, Center, Image, VStack } from "@chakra-ui/react";

import GreenPaw from "../../assets/green-paw.png";
import OrangePaw from "../../assets/orange-paw.png";
import PackLeader from "../../assets/about-location.png";
import { lightGreen, primaryGreen } from "../../constants";

export const AboutLocation = () => {
  return (
    <Box bg="white" as="section" py={{ base: 12, md: 20 }} pos="relative">
      <Box maxW="7xl" mx="auto" px={6}>
        <Grid templateColumns={{ base: "1fr", md: "0.7fr 1fr" }} gap={20}>
          <GridItem>
            <Center h="100%">
              <Image width="100%" height="auto" borderRadius="lg" aspectRatio={1} src={PackLeader} alt="Sarah Squire" />
            </Center>
          </GridItem>
          <GridItem>
            <VStack align="flex-start" justify="center" spacing={6}>
              <Heading as="h2" size="lg" color={lightGreen} fontFamily="'Baloo 2', sans-serif">
                Convenient Location
              </Heading>
              <Heading as="h2" size="xl" color={primaryGreen} fontFamily="'Baloo 2', sans-serif">
                Located conveniently with strong travel links towards the A31 and A338
              </Heading>
              <Text color="black" fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
                Located conveniently with strong travel links towards the A31 and A338, Muttley's Doggy Daycare is easily accessible for dog owners in the Bournemouth and Christchurch areas.
                <br />
                <br />
                Whether you are commuting or living nearby, dropping off and picking up your furry friend is both simple and hassle-free.
                <br />
                <br />
                Keep an eye out for the Mut Bus, coming soon and offering collection and drop off services.
              </Text>
            </VStack>
          </GridItem>
        </Grid>
        <Image src={GreenPaw} alt="Green Paw" position="absolute" top="0" right="0" w="10%" zIndex={9} />
        <Image src={OrangePaw} alt="Orange Paw" position="absolute" bottom="0" left="0" w="10%" zIndex={9} />
      </Box>
    </Box>
  );
};
