import React from "react";
import { Box, Heading, Text, Grid, GridItem, Center, Image, VStack } from "@chakra-ui/react";
import LandingPageAboutImg from "../../assets/landing-page-about.png";
import Paw from "../../assets/paw.png";

import DaycareExperienceImg from "../../assets/daycare-experience.png";
import { lightGreen, primaryGreen } from "../../constants";

export const DaycareExperience = () => {
  return (
    <Box bg="white" as="section" py={{ base: 12, md: 20 }} position="relative">
      <Box maxW="7xl" mx="auto" px={6}>
        <Grid templateColumns={{ base: "1fr", md: "0.7fr 1fr" }} gap={20}>
          <GridItem>
            <Center h="100%">
              <Image h="auto" w="70%" borderRadius="lg" src={DaycareExperienceImg} alt="Dog Field" />
            </Center>
          </GridItem>
          <GridItem>
            <VStack align="flex-start" justify="center" spacing={6}>
              <Heading as="h2" size="lg" color={lightGreen} fontFamily="'Baloo 2', sans-serif">
                Our Experience
              </Heading>
              <Heading as="h2" size="lg" color={primaryGreen} fontFamily="'Baloo 2', sans-serif">
                What sets our daycare apart is the personalised attention each dog receives
              </Heading>
              <Text color="gray.700" fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
                Our experienced staff takes the time to provide one-on-one interaction with every dog, offering focused care that helps reinforce good behaviour and address any emerging behavioural changes.
                <br />
                <br />
                This individualised approach allows us to pick up on and manage any bad habits or behavioural issues early on, ensuring your dog develops positive social skills and maintains a well-rounded temperament.
              </Text>
            </VStack>
          </GridItem>
        </Grid>
        <Image src={Paw} alt="Paw" position="absolute" bottom="0" right="0" w="20%" zIndex={9} />
      </Box>
    </Box>
  );
};
