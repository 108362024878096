import React from "react";
import { Box, Heading, Text, Grid, GridItem, Center, Image, VStack } from "@chakra-ui/react";

import Paw from "../../assets/paw.png";

import PuppyConsultationsImg from "../../assets/puppy-standing.png";
import { lightGreen, primaryGreen } from "../../constants";

export const PuppyConsultations = () => {
  return (
    <Box bg="white" as="section" py={{ base: 12, md: 20 }} position="relative">
      <Box maxW="7xl" mx="auto" px={6}>
        <Grid templateColumns={{ base: "1fr", md: "0.7fr 1fr" }} gap={20}>
          <GridItem>
            <Center h="100%">
              <Image h="auto" w="70%" borderRadius="lg" src={PuppyConsultationsImg} alt="Dog Field" />
            </Center>
          </GridItem>
          <GridItem>
            <VStack align="flex-start" justify="center" spacing={6}>
              <Heading as="h2" size="lg" color={lightGreen} fontFamily="'Baloo 2', sans-serif">
                Puppy Consultations
              </Heading>
              <Heading as="h2" size="lg" color={primaryGreen} fontFamily="'Baloo 2', sans-serif">
                Perfect for new puppy owners seeking guidance and support.
              </Heading>
              <Text color="gray.700" fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
                - Personalised One-on-One Sessions: Tailored to your puppy's unique needs and temperament.
                <br />
                - Expert Advice: Access to a knowledgeable trainer who can answer all your puppy-related questions.
                <br />
                - At-Home Guidance: Tips and strategies to implement at home for consistent training.
                <br />
                - Prevention of Future Issues: Early identification and correction of potential behavioural problems.
                <br />
                - Health and Wellness Tips: Advice on proper nutrition, exercise, and overall puppy care.
                <br />- Progress Tracking: Regular follow-ups to ensure your puppy is on the right track.{" "}
              </Text>
            </VStack>
          </GridItem>
        </Grid>
        <Image src={Paw} alt="Paw" position="absolute" bottom="0" right="0" w="20%" zIndex={9} />
      </Box>
    </Box>
  );
};
