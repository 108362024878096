import React from "react";
import { Box, Heading, Text, Grid, GridItem, Center, Image, VStack, HStack } from "@chakra-ui/react";

import Paw from "../../assets/paw.png";
import WavyBg from "../../assets/primary-green-wavy-bg.png";

import PuppyEarlySupportImg from "../../assets/two-puppies-playing.png";
import { primaryGreen, yellow } from "../../constants";
import { FaPaw } from "react-icons/fa";

const Icon = FaPaw;

const features = [
  "Boost Owner Confidence: Equip owners with the knowledge and tools to handle common puppy challenges, leading to a more enjoyable experience.",
  "Enhance Puppy Confidence: Expose your puppy to new environments and experiences in a positive, controlled way, reducing anxiety and fearfulness.",
  "Foster Socialisation Skills: Encourage healthy interactions with other dogs and people, crucial for well-rounded development.",
  "Prevent Behavioural Issues: Early intervention can help prevent problems such as aggression, fear, and excessive barking.",
  "Strengthen the Bond: Build a strong, positive relationship between owner and puppy through shared learning experiences.",
  "Support Long-Term Success: Lay the foundation for a well-behaved adult dog by starting training early.",
];

export const PuppyEarlySupport = () => {
  return (
    <Box bg="white" as="section" py={{ base: 12, md: 20 }} position="relative">
      <Box maxW="7xl" mx="auto" px={6} mb={60}>
        <Grid templateColumns={{ base: "1fr", md: "0.7fr 1fr" }} gap={20}>
          <GridItem>
            <VStack align="flex-start" justify="center" spacing={6}>
              <Heading as="h2" size="lg" color={primaryGreen} fontFamily="'Baloo 2', sans-serif">
                Key Benefits of Early Support
              </Heading>

              {features.map((feature, index) => (
                <HStack key={index} align="flex-start" spacing={3}>
                  <Icon color={yellow} size="1.5em" />
                  <Text fontSize="md" color={primaryGreen} fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
                    {feature}
                  </Text>
                </HStack>
              ))}
            </VStack>
          </GridItem>
          <GridItem>
            <Center h="100%">
              <Image h="auto" w="70%" borderRadius="lg" src={PuppyEarlySupportImg} alt="Dog Field" />
            </Center>
          </GridItem>
        </Grid>
        <Image src={Paw} alt="Paw" position="absolute" bottom="0" right="0" w="20%" zIndex={9} />
      </Box>
      <Image src={WavyBg} alt="Grass bottom decoration" position="absolute" bottom="-1" left="-10vw" right="0" minW="120vw" zIndex={0} />
    </Box>
  );
};
