import React from "react";
import { Box, Heading, Image, Img } from "@chakra-ui/react";
import WavyTop from "../../assets/light-green-wavy-top.png";
import JoiningProcess from "../../assets/joining-process.png";
import { lightGreen } from "../../constants";
import WavyBg from "../../assets/primary-green-wavy-bg.png";

export const DaycareJoiningProcess = () => {
  return (
    <Box pos="relative" mt={{ base: 10, lg: 20 }} overflow="hidden">
      <Img src={WavyTop} alt="Wavy Top" position="absolute" top={{ base: "-10", lg: "-60px", xl: "-80px" }} left="0" right="0" minW="100vw" zIndex={9} />
      <Box bg={lightGreen} as="section" pb={20}>
        <Heading as="h2" size="lg" color="white" textAlign="center" fontFamily="'Baloo 2', sans-serif" pt={{ base: 10, lg: 20 }}>
          Joining Process
        </Heading>
        <Image src={JoiningProcess} alt="Joining Process" objectFit="cover" objectPosition="center" w="100%" h="auto" zIndex={1} />
      </Box>
      <Image src={WavyBg} alt="Grass bottom decoration" position="absolute" bottom="-1" left="-10vw" right="0" minW="120vw" zIndex={0} />
    </Box>
  );
};
