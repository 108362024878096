import React from "react";
import { Box, Heading, Text, Grid, GridItem, Center, Image, VStack, HStack } from "@chakra-ui/react";
import { FaPaw } from "react-icons/fa";
import LandingPageAboutImg from "../../assets/landing-page.png";
import LightGreenWavyBottom from "../../assets/light-green-wavy-bottom.png";
import Paw from "../../assets/paw.png";
import { lightGreen, yellow } from "../../constants";

const features = ["Over 1000 sqm of indoor activity space, designed to keep dogs engaged and entertained regardless of the weather", "Nestled in 12 acres of pristine nature and conservation land", "Expert Staff. DBS checked and Canine First Aid Trained.", "Fully secure fun-zone where dogs can explore and play freely", "Two fully secure large exercise paddocks", "Easily accessible for dog owners in the Bournemouth, Poole and Christchurch areas", "BCP ‘High Standards’ Licensed and Fully Insured"];

export const LandingPageKnowledge = () => {
  return (
    <>
      <Box bg={lightGreen} as="section" py={{ base: 12, md: 20 }} pos="relative">
        <Box maxW="7xl" mx="auto" px={6}>
          <Grid templateColumns={{ base: "1fr", md: "0.7fr 1fr" }} gap={20}>
            <GridItem>
              <Center h="100%">
                <Image width="100%" height="auto" borderRadius="lg" src={LandingPageAboutImg} alt="Dog Field" />
              </Center>
            </GridItem>
            <GridItem>
              <VStack align="flex-start" justify="center" spacing={6}>
                <Heading as="h2" size="lg" color={yellow} fontFamily="'Baloo 2', sans-serif">
                  Unleash the knowledge
                </Heading>
                <Heading as="h2" size="xl" color="white" fontFamily="'Baloo 2', sans-serif">
                  Everything you need to know, right at your paws
                </Heading>
                {features.map((feature, index) => (
                  <HStack key={index} align="flex-start" spacing={3}>
                    <FaPaw color={yellow} style={{ width: "24px", height: "24px", flexShrink: 0 }} />
                    <Text fontWeight={600} fontSize="md" color="white" fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
                      {feature}
                    </Text>
                  </HStack>
                ))}
              </VStack>
            </GridItem>
          </Grid>
          <Image src={Paw} alt="Paw" position="absolute" bottom="0" right="0" w="20%" zIndex={9} />
        </Box>
      </Box>
      <Image src={LightGreenWavyBottom} />
    </>
  );
};
