import React from "react";
import { Box } from "@chakra-ui/react";
import { Helmet } from "react-helmet-async";

import { PuppyGroups } from "../components/puppies/PuppyGroups";
import { PuppyConsultations } from "../components/puppies/PuppyConsultations";
import { PuppyEarlySupport } from "../components/puppies/PuppyEarlySupport";
import { PuppyGallery } from "../components/puppies/PuppyGallery";
import { PuppyPricing } from "../components/puppies/PuppyPricing";

export const Puppies = () => {
  return (
    <Box bg="white">
      <Helmet>
        <title>Puppy Training & Support - Muttley's Doggy Daycare</title>
        <meta name="description" content="Explore our comprehensive puppy training and support services, including group sessions, one-on-one consultations, and early socialization. Help your puppy grow into a well-behaved and confident adult dog." />
        <meta name="keywords" content="puppy training, puppy socialization, dog behavior, puppy care, Muttley's Doggy Daycare" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Service",
            serviceType: "Puppy Training & Support Services",
            provider: {
              "@type": "Organization",
              name: "Muttley's Doggy Daycare",
              url: "https://muttleysdoggydaycare.com",
            },
            serviceArea: {
              "@type": "Place",
              name: "Bournemouth, Christchurch, Poole",
            },
            description: "Muttley's Doggy Daycare offers a range of services to support your puppy's growth, including group training sessions, personalized consultations, and early socialization opportunities.",
            offers: [
              {
                "@type": "Offer",
                name: "Puppy Groups",
                description: "Providing a structured and supportive environment for young puppies to learn and grow. Includes small group sessions, socialization, and basic obedience training.",
                url: "https://muttleysdoggydaycare.com/puppy-groups",
              },
              {
                "@type": "Offer",
                name: "Puppy Consultations",
                description: "Personalized one-on-one sessions tailored to your puppy's unique needs and temperament. Expert advice and guidance for new puppy owners.",
                url: "https://muttleysdoggydaycare.com/puppy-consultations",
              },
              {
                "@type": "Offer",
                name: "Puppy Early Support",
                description: "Boost your puppy's confidence and socialization skills with early support. Prevent future behavioral issues and strengthen the bond between you and your puppy.",
                url: "https://muttleysdoggydaycare.com/puppy-early-support",
              },
            ],
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": "https://muttleysdoggydaycare.com/puppies",
            },
          })}
        </script>
      </Helmet>

      <PuppyGroups />
      <PuppyConsultations />
      <PuppyEarlySupport />
      <PuppyGallery />
      <PuppyPricing />
    </Box>
  );
};
