import React from "react";
import { Box, Heading, Text, Grid, GridItem, Center, Image, VStack, Flex } from "@chakra-ui/react";
import GreenPaw from "../../assets/green-paw.png";
import { lightGreen, primaryGreen } from "../../constants";

export const ContactOpeningHours = () => {
  return (
    <Box bg="white" as="section" my={{ base: 12, md: 20, lg: 40 }} position="relative">
      <Box maxW="7xl" mx="auto" px={6}>
        <Grid templateColumns={{ base: "1fr", md: "2fr 1fr" }} gap={20}>
          <GridItem>
            <VStack align="flex-start" justify="center" spacing={6}>
              <Heading as="h2" size="lg" color={lightGreen} fontFamily="'Baloo 2', sans-serif">
                Opening Hours
              </Heading>
              <Heading as="h2" size="lg" color={primaryGreen} fontFamily="'Baloo 2', sans-serif">
                We are open and ready to welcome your pets during the following hours:
              </Heading>
              <Flex direction="column" align="center" justify="center">
                <Text color="gray.700" fontFamily="'Baloo 2', sans-serif" lineHeight={1.8}>
                  Monday to Friday: 08:00 - 18:00 <br />
                  Saturday: 09:00 - 17:00 <br />
                  Sunday: 10:00 - 16:00
                </Text>
              </Flex>
            </VStack>
          </GridItem>
          <GridItem>
            <Center h="100%">
              <Image src={GreenPaw} alt="Paw" position="absolute" bottom="0" right="5" w="15%" zIndex={9} />
            </Center>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
};
