import { Box, Button, Heading, VStack, Center, Img, Flex } from "@chakra-ui/react";
import GrassBottom from "../../assets/grass.png";
import DogsImg from "../../assets/dogs-landing-page.png";
import { orange, collarLink, primaryGreen, yellow } from "../../constants";

export const LandingPageHero = () => {
  return (
    <Box position="relative" bgColor={primaryGreen} overflow="hidden">
      <Flex
        as="section"
        h={{ base: "70vh", md: "70vh" }}
        maxW="7xl"
        mx="auto"
        px={{ base: "1.5rem", sm: "3rem", md: "5rem", lg: "10rem" }}
        direction={{ base: "column", md: "row" }}
        alignItems="center"
        justifyContent="space-between"
        pb={{ base: "4rem", md: "0" }} // Add padding bottom on mobile
        zIndex={2}
      >
        <VStack zIndex={3} flex={1} h="full" alignItems={{ base: "center", md: "flex-start" }} justifyContent="center" spacing={{ base: 4, md: 6 }} textAlign={{ base: "center", md: "left" }} mb={{ base: 8, md: 0 }}>
          <Heading color={yellow} fontSize={{ base: "2xl", md: "4xl" }} fontWeight="bold" fontFamily="'Baloo 2', sans-serif" textTransform="uppercase">
            Play, Paws, Repeat!
          </Heading>
          <Heading fontSize={{ base: "xl", md: "3xl" }} fontWeight="bold" fontFamily="'Baloo 2', sans-serif" color="white" textTransform="uppercase">
            Loved by Dogs, Trusted by Owners
          </Heading>
          <Button
            zIndex={5} // Ensure the button is on top of all elements
            bg={orange}
            color="white"
            size={{ base: "md", md: "lg" }}
            onClick={() => window.open(collarLink, "_blank")}
            _hover={{ bg: "white", color: orange }}
            transition="all 0.3s ease"
          >
            Book Here
          </Button>
        </VStack>
        <Center flex={1} h={{ base: "auto", md: "full" }} position="relative" zIndex={2}>
          <Img
            src={DogsImg}
            alt="Happy dogs at daycare"
            position={{ base: "relative", md: "absolute" }} // Relative on mobile, absolute on desktop
            bottom={{ base: "-65px", md: "30px", lg: "37px", xl: "50px" }} // Bottom for desktop, aligned on mobile
            width={{ base: "100%", sm: "80%", md: "130%" }} // Full width on mobile
            maxW="none"
            zIndex={1}
            mt={{ base: "auto", md: "0" }} // Push image to bottom on mobile
          />
        </Center>
        <Img
          src={GrassBottom}
          zIndex={2} // Grass image should be above the background but below the dogs and button
          alt="Grass bottom decoration"
          position="absolute"
          bottom="-1"
          left="0"
          right="0"
          w="100%"
        />
      </Flex>
    </Box>
  );
};
