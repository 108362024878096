import { Box, Button, ButtonGroup, Container, HStack, Image, useBreakpointValue, useDisclosure } from "@chakra-ui/react";
import Logo from "../../assets/logo.png";
import { MobileDrawer } from "./MobileNavbar";
import { ToggleButton } from "./ToggleButton";
import { orange, collarLink, primaryGreen, yellow } from "../../constants";
import { useLocation, useNavigate } from "react-router-dom";

export const Navbar = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const mobileNavbar = useDisclosure();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onButtonClick = (item: string) => {
    if (item === "Home") {
      navigate("/");
      return;
    }
    navigate(item.toLowerCase());
  };

  const isSelected = (item: string) => {
    switch (item) {
      case "Home":
        return pathname === "/";
      case "About":
        return pathname === "/about";
      case "Services":
        return pathname === "/services";
      case "FAQs":
        return pathname === "/faqs";
      case "Contact":
        return pathname === "/contact";
      default:
        return false;
    }
  };

  return (
    <Box as="section" h="5rem" position="sticky" top="0" zIndex="1000">
      <Box h="5rem" bg={primaryGreen} py={1}>
        <Box py="4" mx="5%">
          <HStack justify="space-between">
            <Image src={Logo} width={100} height="auto" onClick={() => navigate("/")} />
            {isDesktop ? (
              <HStack spacing="8">
                <ButtonGroup size="lg" variant="link" colorScheme="whiteAlpha" spacing="8">
                  {["Home", "About", "Services", "FAQs"].map((item) => (
                    <Button color={isSelected(item) ? yellow : "white"} key={item} onClick={() => onButtonClick(item)}>
                      {item}
                    </Button>
                  ))}
                  <Button
                    bg={orange}
                    p={3}
                    color="white"
                    borderRadius={10}
                    onClick={() => {
                      onButtonClick("Contact");
                    }}
                  >
                    Contact Us
                  </Button>
                </ButtonGroup>
              </HStack>
            ) : (
              <>
                <ToggleButton onClick={mobileNavbar.onToggle} isOpen={mobileNavbar.isOpen} aria-label="Open Menu" />
                <MobileDrawer isOpen={mobileNavbar.isOpen} onClose={mobileNavbar.onClose} />
              </>
            )}
          </HStack>
        </Box>
        {/* <Box borderBottomWidth="1px" borderBottomColor="white" borderBottomStyle="solid" mx="5%" pt={4} /> */}
      </Box>
    </Box>
  );
};
